<template>
  <div class="pm-container mt-4">
    <div class="row">
      <div class="col">
        <section class="jumbotron text-center">
          <div class="container-fluid">
            <h2 class="jumbotron-heading">Seminare</h2>
            <p class="lead text-muted">Hier finden Sie unsere Seminare 👩‍🎓</p>
          </div>
        </section>
      </div>
    </div>
    <hr>

    <p>Aktuell keine anstehenden Seminare.</p>
  </div>

</template>

<script>
export default {
  name: "Seminare"
}
</script>

<style scoped>

</style>